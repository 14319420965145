var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"pb-6"},[_c('div',{staticClass:"row align-items-center py-4"},[_c('div',{staticClass:"col-lg-6 col-7"},[_c('h6',{staticClass:"h2 text-white d-inline-block mb-0"})])])]),_c('div',{staticClass:"container-fluid mt--6"},[_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-header mt--4 mb--4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h3',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.tt('inbox')))])]),_c('div',{staticClass:"col-4 text-right"},[_c('base-input',{attrs:{"input-classes":"form-control-sm text-center","placeholder":_vm.tt('search_message')},model:{value:(_vm.inbox.search),callback:function ($$v) {_vm.$set(_vm.inbox, "search", $$v)},expression:"inbox.search"}})],1)])]),_c('div',[_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"height":"350px","header-row-class-name":"thead-light","data":_vm.table.data}},[_c('el-table-column',{attrs:{"label":_vm.tt('sender'),"prop":_vm.tt('sender'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.sender)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.tt('subject'),"prop":_vm.tt('subject'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.subject)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.tt('message'),"prop":_vm.tt('message'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.message)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.tt('date'),"prop":_vm.tt('date'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.date)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"action","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-dropdown',{staticClass:"dropdown",attrs:{"trigger":"click","hide-on-click":true},on:{"command":_vm.handleTableAction}},[_c('span',{staticClass:"btn btn-sm btn-icon-only text-light"},[_c('i',{staticClass:"fas fa-ellipsis-v mt-2"})]),_c('el-dropdown-menu',{staticClass:"dropdown-menu dropdown-menu-arrow show",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/message/inbox-view","tag":"button"}},[_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.tt('view'))+" ")])]),_c('el-dropdown-item',{attrs:{"command":{action:'remove', data:row.id}}},[_vm._v(_vm._s(_vm.tt('delete')))])],1)],1)]}}])})],1)],1)])]),_c('modal',{attrs:{"show":_vm.form.show},on:{"update:show":function($event){return _vm.$set(_vm.form, "show", $event)}}},[_c('template',{slot:"header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.form.title))])]),_c('div',[_c('label',{staticClass:"form-control-label"},[_vm._v(_vm._s(_vm.tt('code'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('base-input',{attrs:{"name":_vm.tt('code'),"placeholder":_vm.tt('code'),"rules":"required"},model:{value:(_vm.inbox.code),callback:function ($$v) {_vm.$set(_vm.inbox, "code", $$v)},expression:"inbox.code"}}),_c('label',{staticClass:"form-control-label"},[_vm._v(_vm._s(_vm.tt('description'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('base-input',{attrs:{"name":_vm.tt('description'),"placeholder":_vm.tt('description'),"rules":"required"},model:{value:(_vm.inbox.description),callback:function ($$v) {_vm.$set(_vm.inbox, "description", $$v)},expression:"inbox.description"}})],1),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"type":"secondary"},on:{"click":function($event){_vm.form.show = false}}},[_vm._v(_vm._s(_vm.tt('close')))]),_c('base-button',{attrs:{"type":"primary"}},[_vm._v(_vm._s(_vm.tt('add')))])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }